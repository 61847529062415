<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>赠送配置</el-breadcrumb-item>
            <el-breadcrumb-item>编辑/添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">

        <el-form-item label="名称" :error="$v.form.name.$error ? '请输入' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>


        <el-form-item label="参与活动商品">
          <el-select v-model="form.productids" filterable allow-create default-first-option placeholder="请选择"
            @change="selectContract">
            <el-option v-for="item in productidsdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动赠送商品">
          <el-select v-model="form.productidgive" filterable allow-create default-first-option placeholder="请选择"
            @change="selectContract">
            <el-option v-for="item in productidsdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赠送数量">
          <el-input v-model.number="form.givenum"></el-input>
        </el-form-item>
        <el-form-item label="状态" :error="$v.form.states.$error ? '请选择状态' : ''">
          <el-radio-group v-model="form.states">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  editRequest,
  getdatilRequest,
  getselectproductlist,
  getselectcouponlist
} from "@/api/zr_productHDMS";
import { getSelectRequest } from "@/api/wx_MemberUserGrade";
import ImageUpload from "@/components/imageUpload";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
import Tinymce from "@/components/tinymce";
export default {
  typeName: "Articletypecreate",
  data() {
    return {
      vipdata: [],
      productidsdata: [],
      couponidsdata: [],
      productidstext: [],
      couponidstext: [],
      form: {
        id: 0,
        name: "",

        addtime: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload,
    tinymce: Tinymce
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.getselectproductlist();


    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getSelect(groupname) {
      getSelectRequest().then(res => {
        this.vipdata = res.data;
      });
    },
    selectContract(e) {
      console.info(e);
      this.form.productids = e.join(",");
      console.info(this.form.productids);
    },
    selectContractcoupon(e) {
      console.info(e);
      this.form.couponids = e.join(",");
      console.info(this.form.couponids);
    },

    getselectproductlist() {
      getselectproductlist().then(res => {
        this.productidsdata = res.data;
      });
    },
    getselectcouponlist() {
      getselectcouponlist().then(res => {
        this.couponidsdata = res.data;
      });
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
        if (this.form.id > 0) {
          // this.productidstext = this.form.productids.split(",").map(Number);
          this.form.productids = parseFloat(this.form.productids)
          this.form.productidgive = parseFloat(this.form.productidgive)
        }
      });
    },
    imageUpload(url) {
      this.form.homeImg = url;
    },
    createAd() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));
        params.ptype = 1
        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },

      states: {
        required
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
